import React  from "react";
import Docs from "./components/Docs";
import Navbar from "../../components/Navbar";
import Banner from "../../components/Banner";



const index = () => {
  

  return (
    <div className="body_wrapper">
      <Navbar/>
      <Banner/>
      <Docs/> 
    </div>
  );
};

export default index;
