import { Environment } from '../enums'

const env = {
  // API_URL: 'http://localhost:8082/api',
  API_URL: 'https://api.alltheapps.io/api',
  SERVER_API: "https://api.levelupmarketplace.io",

  GHL: {
    // CLIENT_ID: '64f8604282165264339a0ccc-lm7nf7n0',
    // CLIENT_SECRET: '84249575-8af0-44bf-adb0-d8cf81d6cecb',

    CLIENT_ID: '656ce3b419a346715ff631b7-lpqq33n8',
    CLIENT_SECRET: '8e7ff180-28bd-464c-aa8a-e8f5d95d9216',
    Location_CLIENT_ID: '656ce3811b5313f4928ade3c-lppxjgvf',
    Location_CLIENT_SECRET: '9d9b7815-2106-4344-bbbf-223d6af3a184',
    Location_SCOPE:
      'contacts.readonly contacts.write campaigns.readonly conversations.readonly conversations.write forms.readonly conversations/message.write links.readonly links.write conversations/message.readonly locations.readonly locations/customFields.readonly locations/customFields.write locations/customValues.write locations/customValues.readonly locations/tags.write locations/tags.readonly locations/tasks.write locations/tasks.readonly opportunities.readonly opportunities.write locations/templates.readonly surveys.readonly users.write users.readonly workflows.readonly',

    SCOPE:
      'contacts.readonly contacts.write locations.readonly locations/customFields.readonly locations/customFields.write workflows.readonly users.readonly users.write campaigns.readonly conversations/message.readonly conversations/message.write locations/customValues.readonly locations/customValues.write opportunities.readonly opportunities.write forms.readonly links.readonly links.write surveys.readonly locations/tasks.readonly locations/tasks.write locations/tags.readonly locations/tags.write locations/templates.readonly',
    REDIRECT: {
      ASSOCIATE: 'https://superadmin.gocsm.io/integrations/associate',
      AGENCY: 'https://superadmin.gocsm.io/integrations/associate/agency',
    },
  },
}

if (process.env.REACT_APP_ENV === Environment.DEVELOPMENT) {
  env.API_URL = 'https://api.alltheapps.io/api'
}
if (process.env.REACT_APP_ENV === Environment.PRODUCTION) {
  env.API_URL = 'https://api.alltheapps.io/api'
}
export default env
