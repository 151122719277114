import React from 'react'
import Faqs from './components/Faqs'
import Navbar from '../../components/Navbar'
import Banner from '../../components/Banner'

const FaqsHome = () => {
  return (
    <div className='body_wrapper'>
      <Navbar />
      <Banner />
      <Faqs />
    </div>
  )
}

export default FaqsHome