import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useAppServices, useUploadImage } from "../../../../../../hook/services";
import { useParams } from "react-router-dom";

const AddProduct = ({ isOpen, onClose, handleRefresh }) => {
    const uploadImage = useUploadImage()
    const AppService = useAppServices()
    const { company_id } = useParams()
    const handleSubmit = async (e) => {
        e.preventDefault();
        const payload = {
            name: e.target.name.value,
            type: "agency",
            company_id: company_id,
        }
        console.log(payload, 'payload')
        const { response } = await AppService.docs_category.createDocs({
            payload
        });
        console.log(response, 'response')
        if (response) {
            onClose();
            handleRefresh()
        }
    };
    if (!isOpen) return null;

    return (
        <div className="docs-modal">
            <div className="docs-modal-content">
                <span className="close" onClick={onClose} style={{ position: "relative", zIndex: "99", cursor: "pointer" }}>
                    &times;
                </span>
                <form className="contact_form" onSubmit={handleSubmit}>
                    <div className="col contact_fill">
                        <h4 style={{ marginBottom: "1rem" }}>Create Modal</h4>
                        <div className="form-group">
                            <h6>Name</h6>
                            <input
                                type="text"
                                className="form-control"
                                name="name"
                                id="name"
                                placeholder="Enter your Category name here"
                            />
                        </div>

                        <div className="col-lg-12  d-flex justify-content-end">
                            <button type="submit" className="btn action_btn thm_btn">
                                Save
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};
AddProduct.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    handleRefresh: PropTypes.func.isRequired,
};
const Default = ({ handleRefresh }) => {
    const [isModalOpen, setModalOpen] = useState(false);

    return (
        <>
            <button onClick={() => setModalOpen(true)} className="">
                Create Category
            </button>
            <AddProduct handleRefresh={handleRefresh} isOpen={isModalOpen} onClose={() => setModalOpen(false)} />
        </>
    )
}

export default Default;
Default.propTypes = {
    handleRefresh: PropTypes.func.isRequired,
};