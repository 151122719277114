import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppServices } from "../../../hook/services";
import Loader from "../../../components/Loader";
import CategoryCreateModal from "./Modals/Category/Create";
import CategoryEditModal from "./Modals/Category/Edit";
import SubCategoryCreateModal from "./Modals/SubCategory/Create";
import SubCategoryEditModal from "./Modals/SubCategory/Edit";
import DocCreateModal from "./Modals/Docs/Create";
import DocEditModal from "./Modals/Docs/Edit";
import Loader2 from "../../../components/Loader2";
import { useLanguageInfo } from "../../../context/language";
const Docs = () => {
  const navigate = useNavigate();
  const [language] = useLanguageInfo()
  const AppService = useAppServices();
  const { company_id, app_id } = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState({});
  const [docs, setDocs] = useState([]);
  const [loader, setloader] = useState(true);
  const [copy, setCopy] = useState(false);
  const [docsloader, setdocsloader] = useState(true);
  const [agency_data, setagency_data] = useState({});
  const [domain_error, setdomain_error] = useState(false);
  const [activeCategory, setactiveCategory] = useState("");
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const handleDocsDetails = (id) => {
    navigate("./docs-details/" + id);
  };
  const handleDocsList = (id) => {
    navigate("./docs-list/" + id);
  };
  const getDocsData = async () => {
    setdocsloader(true);
    const { response } = await AppService.docs.get({
      query: `category=${activeCategory}`,
    });
    console.log(response, "responseresponse");
    if (response) {
      setDocs(response.data);
      setdocsloader(false);
    } else {
      setdocsloader(false);
    }
  };
  useEffect(() => {
    getDocsData();
  }, [activeCategory]);
  const getData = async () => {
    const { response } = await AppService.docs_category.filter({
      query: `company_id=${company_id}&app_id=${app_id}`,
    });
    console.log(response, "responseresponse");
    if (response) {
      setData(response.data);
      setagency_data(response?.profile)
      const temp_category = response.data?.category?.filter((item) => !response.data?.snapshot_data?.some((snapshot_data) => item._id == snapshot_data.data_id))
      setactiveCategory(temp_category[0]?._id)
      setloader(false);
    } else {
      setloader(false);
    }
  };
  const onLoad = () => {
    getData();
  };
  useEffect(() => {
    onLoad();
  }, []);

  const copyToClipboard = () => {
    if (!agency_data?.domain) {
      return setdomain_error(true);
    }
    const text = `https://${agency_data?.domain}/docs`

    // Create a temporary textarea element to hold the text
    const textarea = document.createElement('textarea');
    textarea.value = text;
    textarea.setAttribute('readonly', '');
    textarea.style.position = 'absolute';
    textarea.style.left = '-9999px';

    // Append the textarea to the body
    document.body.appendChild(textarea);

    // Select the text inside the textarea
    textarea.select();

    // Execute the copy command
    document.execCommand('copy');
    setCopy(true)
    // Remove the textarea from the DOM
    document.body.removeChild(textarea);

    setTimeout(() => {
      setCopy(false)
    }, 2000);
  };

  return (
    <div>
      {loader ? (
        <Loader />
      ) : (
        <div className="row">
          {/* SideNav */}

          <div
            className={`col-lg-3 doc_mobile_menu display_none ${isOpen ? "open" : ""
              }`}
          >
            <div>
              <aside className="doc_left_sidebarlist">
                <div className="open_icon" id="left" onClick={toggleSidebar}>
                  <i
                    className={`arrow_carrot-${isOpen ? "left" : "right"}`}
                  ></i>
                </div>
                <div className="scroll hideScrol" style={{ overflowY: "auto", paddingBottom: "85px" }}>
                  <ul className="list-unstyled nav-sidebar">
                    {data?.category?.map((category) => (
                      data?.snapshot_data?.some((snapshot_data) => category._id === snapshot_data.data_id)
                        ?
                        <li className="nav-item" key={category._id}>
                          <a
                            className="nav-link"
                            style={{
                              color:
                                category._id == activeCategory
                                  ? "blue"
                                  : "inherit",
                            }}
                          >
                            {/* <img src="img/side-nav/home.png" alt="" /> */}
                            <span
                              style={{ pointerEvents: 'none' }}
                              onClick={() => setactiveCategory(category._id)}
                            >

                              {language == "english" ? category.name : category?.languages?.filter((item) => item.languageId == language)[0]?.name} (inactive)
                            </span>
                            <CategoryEditModal data={category} main_data={data} handleRefresh={onLoad} />
                          </a>
                        </li>
                        :
                        <li className="nav-item" key={category._id}>
                          <a
                            className="nav-link"
                            style={{
                              color:
                                category._id == activeCategory
                                  ? "blue"
                                  : "inherit",
                            }}
                            onClick={() => setactiveCategory(category._id)}
                          >
                            {/* <img src="img/side-nav/home.png" alt="" /> */}
                            {language == "english" ? category.name : category?.languages?.filter((item) => item.languageId == language)[0]?.name}
                            <CategoryEditModal data={category} main_data={data} handleRefresh={onLoad} />
                          </a>
                        </li>
                    ))}
                  </ul>
                </div>
              </aside>
            </div>
          </div>
          {/* Content */}

          <div className="col-xl-9 doc-middle-content ">
            <div className="toast" style={{ position: "fixed", bottom: '30px', right: "30px", minHeight: "140px", opacity: domain_error ? "1" : "0", zIndex: '99', transition: "opacity 0.5s ease-in-out" }}>
              <div className="toast-header">
                <strong className="mr-auto text-danger">Domain Configration Error !</strong>
                <button type="button" className="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close" style={{ cursor: "pointer" }} onClick={() => setdomain_error(false)}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="toast-body">
                Please configure your white label domain first to copy custom menu link.
              </div>
            </div>
            <div className="docs_btn_container">
              <CategoryCreateModal handleRefresh={onLoad} />
              <SubCategoryCreateModal categories={data?.category} handleRefresh={onLoad} />
              <DocCreateModal categories={data?.category} sub_categories={data?.sub_category} handleRefresh={onLoad} />
              <button onClick={copyToClipboard} className="">
                {
                  copy ?
                    "copied"
                    :
                    "copy custom menu link"
                }
              </button>
            </div>
            <article className="shortcode_info">
              <div className="doc_categories_guide_area sec_pad">
                {
                  docsloader ?
                    <Loader2 />
                    :
                    <div className="row">
                      {data?.sub_category
                        ?.filter((item) => item.category == activeCategory)
                        ?.map((sub_category) => (
                          data?.snapshot_data?.some((snapshot_data) => sub_category._id === snapshot_data.data_id)
                            ?
                            <div className="col-lg-4 col-sm-6" key={sub_category._id}>
                              <div
                                className="categories_guide_item wow fadeInUp"
                                style={{ minHeight: "445px" }}
                              >
                                <div className="doc_tag_title d-flex">
                                  <h5 className=" mb-1">{language == "english" ? sub_category.name : sub_category?.languages?.filter((item) => item.languageId == language)[0]?.name} (inactive)</h5>
                                  <SubCategoryEditModal categories={data?.category} main_data={data} data={sub_category} handleRefresh={onLoad} />
                                </div>
                                <span>Please Activate Sub Category First If You Want To See Docs.</span>
                              </div>
                            </div>
                            :
                            <div className="col-lg-4 col-sm-6" key={sub_category._id}>
                              <div
                                className="categories_guide_item wow fadeInUp"
                                style={{ minHeight: "445px" }}
                              >
                                <div className="doc_tag_title d-flex">
                                  <h5 className=" mb-1">{language == "english" ? sub_category.name : sub_category?.languages?.filter((item) => item.languageId == language)[0]?.name}</h5>
                                  <SubCategoryEditModal categories={data?.category} main_data={data} data={sub_category} handleRefresh={onLoad} />
                                </div>
                                <ul className="list-unstyled tag_list">
                                  {docs
                                    ?.filter(
                                      (item) =>
                                        item.sub_category == sub_category._id &&
                                        item.category == activeCategory
                                    )
                                    ?.map((docs, index) =>
                                      index <= 3 ? (
                                        <li key={docs._id}>
                                          <a

                                          >
                                            <span onClick={() =>
                                              handleDocsDetails(docs._id)
                                            }>
                                              <i className="icon_document_alt"></i>
                                              {language == "english" ? docs.title : docs?.languages?.filter((item) => item.languageId == language)[0]?.title}
                                            </span>
                                            <DocEditModal data={docs} main_data={data} categories={data?.category} sub_categories={data?.sub_category} handleRefresh={onLoad} />
                                          </a>
                                        </li>
                                      ) : (
                                        <></>
                                      )
                                    )}
                                </ul>
                                {docs?.filter(
                                  (item) =>
                                    item.sub_category == sub_category._id &&
                                    item.category == activeCategory
                                )?.length > 4 ? (
                                  <a
                                    className="doc_border_btn"
                                    onClick={() => handleDocsList(sub_category._id)}
                                  >
                                    More (
                                    {docs?.filter(
                                      (item) =>
                                        item.sub_category == sub_category._id &&
                                        item.category == activeCategory
                                    )?.length - 4}
                                    )<i className="arrow_right"></i>
                                  </a>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                        ))}
                    </div>
                }
              </div>
            </article>
          </div>
        </div>
      )}
    </div>
  );
};

export default Docs;
