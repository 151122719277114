import React, { useEffect, useState } from 'react'
import Navbar from '../../components/Navbar'
import { Link, useParams } from 'react-router-dom'
import author from "../../img/forum/author-1.jpg"
import Banner from '../../components/Banner'
import { useAppServices, useUploadImage } from '../../hook/services'
import Loader from "../../components/Loader"
import Swal from 'sweetalert2';
const Index = () => {
    const uploadImage = useUploadImage()
    const AppService = useAppServices()
    const { company_id, app_id } = useParams()
    const [activeTab, setActiveTab] = useState('general_info')
    const [loader, setloader] = useState(true);
    const [domain_update, setdomain_update] = useState(false);
    const [profile, setProfile] = useState({});
    const [agency, setAgency] = useState({});
    const [ProfileImage, setProfileImage] = useState("/assets//images/BlankProfile.jpg")


    const getProfile = async () => {
        const { response } = await AppService.agency.getProfile({
            query: `company_id=${company_id}&app_id=${app_id}`
        });
        if (response) {
            console.log(response, 'responseresponse')
            setAgency(response.data)
            setProfile(response.data?.profile || {})
            setProfileImage(response.data?.profile?.profile_image || "/assets//images/BlankProfile.jpg")

            setloader(false)
        } else {
            setloader(false)
        }
    }
    const onLoad = async () => {
        getProfile()
    }
    useEffect(() => {
        onLoad()
    }, []);

    const handleImageChange = async (e) => {
        const file = e.target.files
        if (file?.[0]) {
            const image_url = await uploadImage({
                file: file[0],
                desiredPath: `design/logo/image`,
                toaster: false
            })
                .then((res) => {
                    setProfileImage(res.response.data)
                    return res.response.data;
                })
                .catch((err) => {
                    return null;
                });
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        const general_info = {
            name: e.target.name.value,
            company_name: e.target.company_name.value,
            country: e.target.country.value,
            address: e.target.address.value,
            phone: e.target.phone.value,
            email: e.target.email.value,
            website: e.target.website.value,
        }
        const social_info = {

            linkedin: e.target.linkedin.value,
            twitter: e.target.twitter.value,
            facebook: e.target.facebook.value,
            instagram: e.target.instagram.value,
        }
        const profile = {
            profile_image: ProfileImage,
            personalinfo: general_info,
            socail_links: social_info,
        }
        console.log(profile, 'profileprofile')
        const payload = {
            profile: profile,
            domain: e.target.domain.value,
            domain_update: domain_update,
            app_id: app_id,
            companyId: company_id,
        }
        console.log(payload, 'payload')
        const toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
        });
        if (domain_update) {
            const domain_response = await AppService.domain.update({
                payload: {
                    domain: e.target.domain.value,
                    domain_update: domain_update,
                }
            });
            if (domain_response.response) {
                const { response } = await AppService.agency.update_with_company_id({
                    payload
                });
                console.log(response, 'response')
                if (response) {
                    setdomain_update(false)
                    toast.fire({
                        icon: 'success',
                        title: 'Data Saved Successfully',
                        padding: '10px 20px',
                    });
                } else {
                    toast.fire({
                        icon: 'error',
                        title: 'Something went wrong while saving data',
                        padding: '10px 20px',
                    });
                }
            } else {
                toast.fire({
                    icon: 'error',
                    title: 'Something went wrong while saving data',
                    padding: '10px 20px',
                });
            }
        } else {

            const { response } = await AppService.agency.update_with_company_id({
                payload
            });
            console.log(response, 'response')
            if (response) {
                setdomain_update(false)

                toast.fire({
                    icon: 'success',
                    title: 'Data Saved Successfully',
                    padding: '10px 20px',
                });
            } else {
                toast.fire({
                    icon: 'error',
                    title: 'Something went wrong while saving data',
                    padding: '10px 20px',
                });
            }
        }
    }
    return (
        <>
            {
                loader ?
                    <Loader />
                    :
                    <>

                        <div className="body_wrapper">
                            <Navbar />
                            <Banner />
                            <section className="forum-user-wrapper">
                                <div className="container">
                                    <div className="row forum_main_inner">
                                        <div className="col-lg-3">
                                            <div className="author_option">
                                                <div className="author_img d-flex" style={{ flexDirection: "column", gap: "1rem" }}>
                                                    <img className="img-fluid" src={ProfileImage} alt="" />
                                                    <button type="button" className="save_btn" style={{
                                                        position: "relative",
                                                        overflow: "hidden",
                                                        cursor: "pointer"
                                                    }}>
                                                        Upload Logo
                                                        <input type="file" id='profile_image' name='profile_image' style={{
                                                            position: "absolute",
                                                            cursor: "pointer",
                                                            fontSize: "50px",
                                                            opacity: 0,
                                                            right: 0,
                                                            top: 0,
                                                        }}
                                                            onChange={(e) => handleImageChange(e)}
                                                        />
                                                    </button>
                                                </div>
                                                <ul className="nav nav-tabs flex-column" id="myTab" role="tablist">
                                                    <li className="nav-item" role="presentation" onClick={() => setActiveTab("general_info")}>
                                                        <Link className={`nav-link ${activeTab == "general_info" ? "active" : ""}`} id="profile-tab" data-toggle="tab" role="tab"
                                                            aria-controls="profile" aria-selected="false">
                                                            <i className="icon_documents"></i> General Info
                                                        </Link>
                                                    </li>
                                                    <li className="nav-item" role="presentation" onClick={() => setActiveTab("social_info")}>
                                                        <Link className={`nav-link ${activeTab == "social_info" ? "active" : ""}`} id="contact-tab" data-toggle="tab" role="tab"
                                                            aria-controls="contact" aria-selected="false">
                                                            <i className="icon_chat"></i> Social Info
                                                        </Link>
                                                    </li>
                                                    <li className="nav-item" role="presentation" onClick={() => setActiveTab("domain_configration")}>
                                                        <Link className={`nav-link ${activeTab == "domain_configration" ? "active" : ""}`} id="contact-tab" data-toggle="tab" role="tab"
                                                            aria-controls="contact" aria-selected="false">
                                                            <i className="icon_chat"></i> Domain Configration
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-lg-9">
                                            <div className="forum_body_area">
                                                <div className="forum_topic_list">
                                                    <form className="tab-content" id="myTabContent" onSubmit={handleSubmit}>
                                                        <div className={`tab-pane fade ${activeTab == "general_info" ? "show active" : ""}`} id="home" role="tabpanel"
                                                            aria-labelledby="home-tab">
                                                            <div className="profile_info">
                                                                {/* Form */}
                                                                <div className="col contact_fill">
                                                                    <div className="form-group">
                                                                        <h6>Name</h6>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            name="name"
                                                                            defaultValue={profile?.personalinfo?.name}
                                                                            placeholder="Enter your name here"
                                                                        />
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <h6>Email</h6>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            name="email"
                                                                            defaultValue={profile?.personalinfo?.email}

                                                                            placeholder="Enter your email here" // Update placeholder text
                                                                        />
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <h6>Company Name</h6>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            name="company_name"
                                                                            defaultValue={profile?.personalinfo?.company_name}

                                                                            placeholder="Enter your company name here"
                                                                        />
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <h6>Country Name</h6>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            name="country"

                                                                            defaultValue={profile?.personalinfo?.country}
                                                                            placeholder="Enter your country here"
                                                                        />
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <h6>Address</h6>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            name="address"
                                                                            defaultValue={profile?.personalinfo?.address}

                                                                            placeholder="Enter your address here"
                                                                        />
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <h6>Phone</h6>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            name="phone"
                                                                            defaultValue={profile?.personalinfo?.phone}
                                                                            placeholder="Enter your phone here"
                                                                        />
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <h6>Website</h6>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            name="website"
                                                                            defaultValue={profile?.personalinfo?.website} placeholder="Enter your website here"
                                                                        />
                                                                    </div>
                                                                    <div className="col-lg-12 form-group">
                                                                        <button type="submit" className="save_btn">
                                                                            Save
                                                                        </button>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div className={`tab-pane fade ${activeTab == "social_info" ? "show active" : ""}`} id="home" role="tabpanel"
                                                            aria-labelledby="home-tab">
                                                            <div className="profile_info">
                                                                {/* Form */}
                                                                <div className="col contact_fill">
                                                                    <div className="form-group">
                                                                        <h6>Facebook Link</h6>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            name="facebook"
                                                                            defaultValue={profile?.socail_links?.facebook}
                                                                            placeholder="Enter your facebook link here"
                                                                        />
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <h6>Instagram Link</h6>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            name="instagram"
                                                                            defaultValue={profile?.socail_links?.instagram}
                                                                            placeholder="Enter your instagram link here"
                                                                        />
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <h6>Twitter Link</h6>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            name="twitter"
                                                                            defaultValue={profile?.socail_links?.twitter} placeholder="Enter your twitter link here"
                                                                        />
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <h6>LinkedIn Link</h6>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            name="linkedin"
                                                                            defaultValue={profile?.socail_links?.linkedin}
                                                                            placeholder="Enter your linkedin link here"
                                                                        />
                                                                    </div>
                                                                    <div className="col-lg-12 form-group">
                                                                        <button type="submit" className="save_btn">
                                                                            Save
                                                                        </button>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div className={`tab-pane fade ${activeTab == "domain_configration" ? "show active" : ""}`} id="home" role="tabpanel"
                                                            aria-labelledby="home-tab">
                                                            <div className="profile_info">
                                                                {/* Form */}
                                                                <div className="col contact_fill">
                                                                    <div className="form-group">
                                                                        <h6>Domain</h6>
                                                                        <input
                                                                            onChange={() => setdomain_update(true)}
                                                                            type="text"
                                                                            className="form-control"
                                                                            name="domain"
                                                                            defaultValue={agency?.domain}
                                                                            placeholder="Enter your Domain here"
                                                                        />
                                                                    </div>
                                                                    <div style={{ paddingTop: "3rem", paddingX: "2rem" }}>
                                                                        <h6
                                                                        >
                                                                            Connect white label domain
                                                                        </h6>
                                                                        <p
                                                                            style={{ fontSize: "15px" }}
                                                                        >
                                                                            Connect your own domain to the system (without https://)
                                                                            knowledebase.yourdomain.com
                                                                        </p>
                                                                        <p
                                                                            style={{ fontSize: "15px" }}
                                                                        >
                                                                            You can use any domain you own or you can create a
                                                                            sub-domain like knowledebase.yourdomain.com to get
                                                                            white-labeled access to the platform.
                                                                        </p>
                                                                        <p
                                                                            style={{ fontSize: "15px" }}
                                                                        >
                                                                            <b>1.</b> Refer to your domain registrar's control panel,
                                                                            choose desired domain zone.
                                                                        </p>
                                                                        <p
                                                                            style={{ fontSize: "15px" }}
                                                                        >
                                                                            <b>2.</b>Create a record of{" "}
                                                                            <u>
                                                                                <b>ANAME</b>
                                                                            </u>{" "}
                                                                            type, enter your domain/subdomain name and point it to{" "}
                                                                            <u>
                                                                                <b>161.35.33.153</b>
                                                                            </u>
                                                                        </p>
                                                                        <p
                                                                            style={{ fontSize: "15px" }}
                                                                        >
                                                                            <b>3.</b> We will do the rest!
                                                                        </p>
                                                                    </div>
                                                                    <div className="col-lg-12 form-group">
                                                                        <button type="submit" className="save_btn">
                                                                            Save
                                                                        </button>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            {/* <section className="doc_subscribe_area doc_subs_full">
                    <div className="doc_subscribe_inner">
                        <img className="one" src={subShape1} alt="" />
                        <img className="two" src={subShape2} alt="" />
                        <div className="container">
                            <div className="d-flex">
                                <div className="text wow fadeInLeft" data-wow-delay="0.2s">
                                    <h2>Great Customer <br />Relationships start here</h2>
                                </div>
                                <form className="doc_subscribe_form wow fadeInRight mailchimp" data-wow-delay="0.4s">
                                    <div className="form-group">
                                        <div className="input-fill">
                                            <input type="email" name="EMAIL" id="email" className="memail" placeholder="Your work email" />
                                        </div>
                                        <button type="submit" className="submit_btn">Get started</button>
                                        <p className="mchimp-errmessage" style={{ display: 'none' }}></p>
                                        <p className="mchimp-sucmessage" style={{ display: 'none' }}></p>
                                    </div>
                                    <ul className="list-unstyled">
                                        <li><a href="#">Messenger</a></li>
                                        <li><a href="#">Product Tours</a></li>
                                        <li><a href="#">Inbox and more</a></li>
                                    </ul>
                                </form>
                            </div>
                        </div>
                    </div>
                </section> */}
                        </div>

                    </>
            }
        </>
    )
}

export default Index