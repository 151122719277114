import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useAppServices, useUploadImage } from "../../../../../../hook/services";
import { useParams } from "react-router-dom";
import Editor from "../../../../../../components/editor"
const AddProduct = ({ isOpen, onClose, categories, sub_categories, handleRefresh }) => {
    const uploadImage = useUploadImage()
    const AppService = useAppServices()
    const { company_id } = useParams()
    const [selectedCategory, setSelectedCategory] = useState(null)
    const [doc, setDoc] = useState('')
    const handleSubmit = async (e) => {
        e.preventDefault();
        const payload = {
            title: e.target.title.value,
            category: e.target.category.value,
            sub_category: e.target.sub_category.value,
            type: "agency",
            company_id: company_id,
            doc: doc,
        }
        console.log(payload, 'payload')
        const { response } = await AppService.docs.createDocs({
            payload
        });
        console.log(response, 'response')
        if (response) {
            onClose();
            handleRefresh()
        }
    };
    if (!isOpen) return null;

    return (
        <div className="docs-modal">
            <div className="docs-modal-content">
                <span className="close" onClick={onClose} style={{ position: "relative", zIndex: "99", cursor: "pointer" }}>
                    &times;
                </span>
                <form className="contact_form" onSubmit={handleSubmit}>
                    <div className="col contact_fill">
                        <h4 style={{ marginBottom: "1rem" }}>Create Modal</h4>
                        <div className="form-group">
                            <h6>Title</h6>
                            <input
                                type="text"
                                className="form-control"
                                name="title"
                                id="name"
                                placeholder="Enter your Doc title here"
                            />
                        </div>
                        <div className="form-group">
                            <h6>Category</h6>
                            <select name="category" className="form-control" onChange={(e) => setSelectedCategory(e.target.value)}>
                                {
                                    categories.filter((item) => item.type != 'super-admin')?.map((category) =>

                                        <option key={category._id} value={category._id}>{category.name}</option>
                                    )
                                }
                            </select>
                        </div>
                        {
                            selectedCategory &&
                            <div className="form-group">
                                <h6>Sub Category</h6>
                                <select name="sub_category" className="form-control">
                                    {
                                        sub_categories?.filter((item) => item.category == selectedCategory && item.type != 'super-admin')?.map((sub_category) =>

                                            <option key={sub_category._id} value={sub_category._id}>{sub_category.name}</option>
                                        )
                                    }
                                </select>
                            </div>
                        }
                        <div className="form-group">
                            <h6>Doc</h6>
                            <Editor
                                handleChange={(data) => {
                                    console.log('html', data)
                                    setDoc(data)
                                }}
                                data={doc}
                            />
                        </div>
                        <div className="col-lg-12  d-flex justify-content-end">
                            <button type="submit" className="btn action_btn thm_btn">
                                Save
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};
AddProduct.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    categories: PropTypes.array.isRequired,
    sub_categories: PropTypes.array.isRequired,
    handleRefresh: PropTypes.func.isRequired,
};
const Default = ({ categories, sub_categories, handleRefresh }) => {
    const [isModalOpen, setModalOpen] = useState(false);
    // const temp_categories=categories?.map((category)=>({
    //     ...category,
    // }))
    return (
        <>
            <button onClick={() => setModalOpen(true)} className="">
                Create Doc
            </button>
            <AddProduct handleRefresh={handleRefresh} isOpen={isModalOpen} categories={categories} sub_categories={sub_categories} onClose={() => setModalOpen(false)} />
        </>
    )
}
Default.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    categories: PropTypes.array.isRequired,
    sub_categories: PropTypes.array.isRequired,
    handleRefresh: PropTypes.func.isRequired,
};
export default Default;
