import React from "react";
import "./App.css";
import "./assets/bootstrap/css/bootstrap.min.css";
import "./css/style-main.css";
import "./css/responsive.css";
// import "./css/style-main.css.map"
import "./assets/animation/animate.css";
import "./assets/elagent-icon/style.css";
import "./assets/ionicons/ionicons";
import Home from "./pages/home/index";
import Contact from "./pages/contact/index";
import Signin from "./pages/signin/Index";
import Signup from "./pages/signup/Index";
import ErrorPage from "./pages/404Page/Index";
import ForumRoot from "./pages/forumRoot/Index";
import ForumTopics from "./pages/forumTopics/Index";
import ForumDetails from "./pages/forumDetails/Index";
import Docs from "./pages/Docs/index";
import DocsDetails from "./pages/Docs/components/DocsDetails";
import DocsList from "./pages/Docs/components/DocsList";
import UserProfile from "./pages/userProfile/Index";


import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import Faqs from "./pages/Faqs/index";
function App() {
  return (
    <>
      <Router>
        <Routes>
          {/* <Route exact path="/" element={<Home />}></Route> */}
          {/* <Route exact path="/contact" element={<Contact />}></Route> */}
          <Route exact path="/:app_id/:company_id/faqs" element={<Faqs />}></Route>
          <Route exact path="/:app_id/:company_id/profile" element={<UserProfile />}></Route>
          <Route exact path="/:app_id/:company_id/docs" element={<Docs />}></Route>
          <Route
            exact
            path="/:app_id/:company_id/docs/docs-details/:doc_id"
            element={<DocsDetails />}
          ></Route>
          <Route
            exact
            path="/:app_id/:company_id/docs/docs-list/:sub_category_id"
            element={<DocsList />}
          ></Route>
          {/* <Route exact path="/signin" element={<Signin />}></Route>
          <Route exact path="/signup" element={<Signup />}></Route>
          <Route exact path="/404" element={<ErrorPage />}></Route> */}
          {/* <Route exact path="/forumroot" element={<ForumRoot />}></Route>
          <Route exact path="/forumtopics" element={<ForumTopics />}></Route>
          <Route exact path="/forumdetails" element={<ForumDetails />}></Route> */}

        </Routes>
      </Router>
    </>
  );
}

export default App;
