import React, { useState } from 'react';
import { useAppServices } from '../../../../../../hook/services';
import { useParams } from 'react-router-dom';
import PropTypes from "prop-types";

const MyModal = ({ data, main_data, categories, handleRefresh }) => {
    const [showModal, setShowModal] = useState(false);
    const [category, setCategory] = useState('');
    const AppService = useAppServices()
    const { company_id } = useParams()
    const handleDelete = async () => {
        const { response } = await AppService.docs_sub_category.delete({
            query: `_id=${data._id}`
        });
        if (response) {
            setShowModal(false)
        }
    }
    const handleActivate = async (type) => {
        const payload = {
            type: type,
            company_id: company_id,
            category: category,
            _id: data._id,
        }
        console.log(payload, 'payload')
        const { response } = await AppService.docs_sub_category.take_snapshot({
            payload
        });
        console.log(response, 'response')
        if (response) {
            setShowModal(false)
            handleRefresh()
        }
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        const payload = {
            name: e.target.name.value,
            category: e.target.category.value,
            _id: data?._id,
        }
        console.log(payload, 'payload')
        const { response } = await AppService.docs_sub_category.update({
            payload
        });
        console.log(response, 'response')
        if (response) {
            setShowModal(false);
        }
    };
    return (
        <>
            <i className="ml-3 bi bi-pencil-square" style={{ cursor: "pointer" }} onClick={setShowModal}></i>
            {showModal && (
                <div className="modal fade show" id="exampleModalLong" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true" style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.5)' }}>
                    <div className="modal-dialog  modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLongTitle">Edit Sub Category</h5>
                                <button type="button" className="close" onClick={() => setShowModal(false)} aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <form onSubmit={handleSubmit}>
                                {data?.type == "super-admin" ?
                                    <>
                                        <div className="modal-body">
                                            <div>
                                                <b>Note:</b>
                                                <p className='mt-1'>make sure if you will activate or clone sub category it's docs will also be activate or clone.</p>
                                            </div>
                                            <div className="form-group">
                                                <label className="col-form-label text-dark">Category</label>
                                                <select name="category" className="form-control" onChange={(e) => setCategory(e.target.value)}>
                                                    {
                                                        categories?.filter((item) => item.type != 'super-admin')?.map((category) =>

                                                            <option key={category._id} value={category._id}>{category.name}</option>
                                                        )
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-secondary" onClick={() => setShowModal(false)}>Close</button>
                                            <button type="button" className="btn btn-primary " onClick={() => handleActivate('clone')}>Clone</button>

                                            {
                                                main_data?.snapshot_data?.some((snapshot_data) => data._id === snapshot_data.data_id)
                                                    ?
                                                    <button type="button" className="btn btn-primary" onClick={() => handleActivate('activate')}>Activate</button>
                                                    :
                                                    <button type="button" className="btn btn-primary " onClick={() => handleActivate('deactivate')}>De Activate</button>
                                            }
                                        </div>
                                    </>
                                    :
                                    <>

                                        <div className="modal-body">
                                            <div className="form-group" >
                                                <label className="col-form-label text-dark">Name</label>
                                                <input name='name' type="text" className="form-control" defaultValue={data?.name} />
                                            </div>
                                            <div className="form-group">
                                                <label className="col-form-label text-dark">Category</label>
                                                <select name="category" className="form-control">
                                                    {
                                                        categories.filter((item) => item.type != 'super-admin')?.map((category) =>

                                                            <option key={category._id} value={category._id}>{category.name}</option>
                                                        )
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="submit" className="btn btn-danger btn-sm" onClick={handleDelete}> <i className="bi bi-trash3"></i></button>
                                            <button type="button" className="btn btn-secondary" onClick={() => setShowModal(false)}>Close</button>
                                            <button type="submit" className="btn btn-primary">Save changes</button>
                                        </div>
                                    </>

                                }
                            </form>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default MyModal;
MyModal.propTypes = {
    data: PropTypes.object.isRequired,
    main_data: PropTypes.object.isRequired,
    categories: PropTypes.array.isRequired,
    handleRefresh: PropTypes.func.isRequired,
};